import React, { useState } from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { navigate } from 'gatsby-link'

import * as S from "./styled"

const Blog = ({ posts }) => {

    const { recentBlogList } = useStaticQuery(
        graphql`
          query {
                recentBlogList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(blog)/"}}, sort: {fields: frontmatter___date, order: DESC}, limit: 5) {
                        edges {
                        node {
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                            date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
                                description
                                autor
                                title
                                img {
                                    childImageSharp {
                                        fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const [formData, setFormData] = useState({
        name: '',
        number: '',
        email: '',
        message: '',
      })

    const encode = (data) => {
        return Object.keys(data)
          .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
          .join('&')
      }  


    const handleChange = (event) => {
        const { name, value } = event.target

        setFormData({ ...formData, [name]: value })
    }
    
    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target

        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': form.getAttribute('name'),
            ...formData,
          }),
        })
          .then(() => navigate(form.getAttribute('action')))
          .catch((error) => alert(error))
      }

    return (
        <>
            <S.BlogMainContainer>
                <S.BlogPosts>
                    {
                        posts.map(({ node: { id, fields: { slug }, frontmatter: { date, description, autor, title, img } } }) => {
                            return (
                                <S.BlogPost key={id}>
                                    <S.Img>
                                        <Img fluid={img.childImageSharp.fluid}  />
                                    </S.Img>
                                    <h2>{title}</h2>
                                    <h4>Publicado por {autor} {date}</h4>
                                    <p>{description}</p>
                                    <span><Link to={`/blog${slug}`}>Saiba mais</Link></span>
                                </S.BlogPost>
                            )
                        })
                    }
                </S.BlogPosts>
                <S.BlogSidebar>
                    <S.FormContainer>
                        <h6>Cadastre-se e receba novidades da sala de aula virtual:</h6>
                        <form 
                            name="sala-de-aula-virtual"
                            method="POST"
                            action="/obrigado"
                            data-netlify="true"
                            onSubmit={handleSubmit}
                            >
                            <S.Field>
                                <label htmlFor="name" >Digite seu nome*</label>
                                <input type="text" name="name" id="name" required={true}
                                    onChange={handleChange} />
                            </S.Field>
                            <S.Field>
                                <label htmlFor="email" >Digite seu e-mail*</label>
                                <input type="email" name="email" id="email" required={true}
                                    onChange={handleChange} />
                            </S.Field>
                            <S.Field>
                                <button type="submit">Cadastrar</button>
                            </S.Field>
                        </form>
                    </S.FormContainer>
                    <hr />
                    <S.RecentPostsContainer>
                        <h6>Últimos posts</h6>
                        <S.RecentPosts>
                            {
                                recentBlogList.edges.map(({ node: { id, fields: { slug }, frontmatter: { date, title, img } } }) => {
                                    return (
                                        <S.RecentPost key={id}>
                                            <S.RecentImg>
                                                <Img fluid={img.childImageSharp.fluid} />
                                            </S.RecentImg>
                                            <S.RecentPostInfos>
                                                <Link to={`/blog${slug}`}><h2>{title}</h2></Link>
                                                <span>{date}</span>
                                            </S.RecentPostInfos>
                                        </S.RecentPost>
                                    )
                                })
                            }
                        </S.RecentPosts>
                    </S.RecentPostsContainer>
                </S.BlogSidebar>
            </S.BlogMainContainer>
        </>
    )
}

Blog.propTypes = {
    posts: PropTypes.array.isRequired,
}

export default Blog