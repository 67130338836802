import React from "react"
import propTypes from "prop-types"
import { Link } from "gatsby"

import * as S from "./styled"

const Pagination = ({
  isFirst,
  isLast,
  currentPage,
  numPages,
  prevPage,
  nextPage
}) => (
    <S.PaginationWrapper>
      <div>
        {
          !isFirst && (
            <Link to={prevPage}>
              <S.PaginationButton>
                <h6>{currentPage - 1}</h6>
              </S.PaginationButton>
            </Link>
          )
        }
        <S.PaginationButton className="first">
          <h6>{currentPage}</h6>
        </S.PaginationButton>
        {
          !isLast && (
            <>
              {
                currentPage === numPages - 1 ? '' : 
                (
                  <Link to={nextPage}  >
                    <S.PaginationButton>
                      <h6>{currentPage + 1}</h6>
                    </S.PaginationButton>
                  </Link>
                )
              }
              <span>...</span>
              <Link to={`/blog/page/${numPages}`}  >
                <S.PaginationButton>
                  <h6>{numPages}</h6>
                </S.PaginationButton>
              </Link> 
            </>
            )
        }
      </div>
    </S.PaginationWrapper>
  )

Pagination.propTypes = {
  isFirst: propTypes.bool.isRequired,
  isLast: propTypes.bool.isRequired,
  currentPage: propTypes.number.isRequired,
  numPages: propTypes.number.isRequired,
  prevPage: propTypes.string,
  nextPage: propTypes.string,
}

export default Pagination