import styled from 'styled-components'
import media from 'styled-media-query'

export const PaginationWrapper = styled.section`
  align-items: center;
  color: #8899a6;
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 3rem;
  
  div {
    color: #8899a6;
    text-decoration: none;
    transition: color 0.5s;

    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      font-family: 'Montserrat', sans-serif;
      color: #575757;
      font-size: 1rem;
      font-weight: 500;
      margin: 0 2px;
    }
    
    &:hover {
      color: #1fa1f2;
    }
  }

  ${media.lessThan("medium")`
    justify-content: center;
  `}
`

export const PaginationButton = styled.div`
  display: inline-block;
  background-color: #E6E7E9;
  padding: 10px 15px;         
  border-radius: 10%;
  width: 50px;
  height: 25px;
  width: 17px;
  border-radius: 50%;

  margin: 2px;

  h6 {
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
    color: #FFF;
    font-size: 1.5rem;
    font-weight: 700;
  }

  &.first {
    background-color: #3A8CA3;
  }
`