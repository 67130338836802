import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Blog from "../components/Blog"
import Pagination from "../components/Pagination"

const BlogPost = ( props ) => {
    
    const blogData = props.data.allMarkdownRemark.edges

    const { currentPage, numPages } = props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? `/blog` : `/blog/page/${currentPage - 1}`
    const nextPage = `/blog/page/${currentPage + 1}`

    return (
        <Layout title="Notícias">
            <SEO title="Home" />
            <Blog posts={blogData} />
            <Pagination 
                isFirst={isFirst}
                isLast={isLast}   
                currentPage={currentPage}
                numPages={numPages}
                prevPage={prevPage}
                nextPage={nextPage}
            />
        </Layout>
    )
}

export const query = graphql`
        query BlogList($skip: Int!, $limit: Int!) {
            allMarkdownRemark(
                filter: {fileAbsolutePath: {regex: "/(blog)/"  }}
                limit: $limit
                skip: $skip
                ) {
                edges {
                    node {
                        id
                        fields {
                            slug
                        }
                        frontmatter {
                            date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
                            description
                            autor
                            title
                            img {
                                    childImageSharp {
                                        fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                        }
                    }
                }
            }
        }
    `

export default BlogPost

