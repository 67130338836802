import styled from 'styled-components'
import media from 'styled-media-query'

export const BlogMainContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.6fr;
    grid-gap: 80px;
    max-width: 90%;
    padding: 10px;
    margin: 0 auto;

    ${media.lessThan("medium")`
        grid-template-columns: 1fr;        
    `}
`

export const BlogPosts = styled.div``

export const BlogPost = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    h2 {
        font-family: 'Titillium Web', sans-serif;
        font-size: 2.4rem;
        font-weight: 700;
        color: #29559C;

        text-transform: uppercase;

        margin: 20px 0 5px 0;
    }

    h4 {
        font-family: 'Titillium Web', sans-serif;
        font-size: 1.15rem;
        font-weight: 700;
        color: #525252;
        opacity: 0.8;

        text-transform: uppercase;

        margin-bottom: 20px; 
    }

    p {
        font-family: 'Montserrat', sans-serif;
        font-size: 0.9em;
        font-weight: 700;
        color: #969696;

        line-height: 22px
    }

    span {
        font-family: 'Montserrat', sans-serif;
        font-size: 0.9em;
        font-weight: 700;
        color: #fff;

        padding: 15px 30px;
        background: transparent linear-gradient(180deg, #37A19B 0%, #416DB2 100%) 0% 0% no-repeat padding-box;
        border-radius: 24px;

        margin: 40px 0;

        transition: all 0.45s ease;

        a {
            color: #fff;
        }

        cursor: pointer;

        &:hover{
            transform: scale(1.05);
        }    
    }

`

export const Img = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: #29559C;
    background-image: url(${ props => props.img }); 
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-position: center center; 
`

export const BlogSidebar = styled.div`

    hr {
        margin-top: 30px;
        margin-bottom: 20px;
        border: none;
        height: 0.5px;
        background-color: #ACACAC;
        
    }
`

export const FormContainer = styled.div`
    background: transparent linear-gradient(180deg, #4876B5 0%, #243B5B 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px;
    padding: 40px 30px 30px 30px;

    h6 {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.1em;
        font-weight: 700;
        color: #fff;

        line-height: 30px;

        margin-bottom: 30px;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
`

export const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    width: 100%;

    margin-bottom: 20px;

    label {
        font-family: 'Montserrat', sans-serif;
        font-size: 1em;
        font-weight: 700;
        color: #fff;

        margin-bottom: 10px;

    }

    input {
        width: 90%;

        align-self: center;

        padding: 10px 20px;
        border: none;
        border-radius: 24px;

        font-family: 'Montserrat', sans-serif;
        font-size: 1em;
        font-weight: 700;
        color: #303030;b

        &:focus {
            border: none;
            outline: none!important;
        }
    }

    button {
        width: 100%;
        padding: 10px 20px;

        background: #38989E 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000052;

        font-family: 'Montserrat', sans-serif;
        font-size: 1.1em;
        font-weight: 900;
        color: #fff;

        border: none;
        border-radius: 24px;

        margin-top: 20px;

        transition: all 0.45s ease;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &:hover {
            transform: scale(1.02)
        }
    }

`

export const RecentPostsContainer = styled.div`
    h6 {
        font-family: 'Titillium Web', sans-serif;
        font-size: 2.4rem;
        font-weight: 700;
        color: #36A09A;

        margin: 50px 0px;
    }
`

export const RecentPosts = styled.div``

export const RecentPost = styled.div`
    display: flex;
    flex-direction: row;

    height: 170px;

    margin-bottom: 40px;


    ${media.lessThan("large")`
        flex-direction: column;
        height: 100%;

    `}
`

export const RecentImg = styled.div`
    display: block;
    width: 40%;
    height: 100px;
    border-radius: 38px;
    background-color: #29559C;
    background-image: url(${ props => props.img });
    overflow: hidden; 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    ${media.lessThan("large")`
      width: 100%;
      height: 200px;
      margin-bottom: 10px;
    `}

    img {
        width: 100%;
    }    
`

export const RecentPostInfos = styled.div`

    width: 60%;

    margin-left: 10px;

    h2 {
        font-family: 'Titillium Web', sans-serif;
        font-size: 2rem;
        font-weight: 700;
        color: #29559C;
        text-transform: uppercase;

        overflow: hidden;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        margin-bottom: 10px;

        transition: all 0.45s ease;

        cursor: pointer;

        &:hover{
            color: #36A09A;
        }
    }

    span {
        font-family: 'Titillium Web', sans-serif;
        font-size: 1.15rem;
        font-weight: 700;
        color: #525252;
        opacity: 0.8;

        text-transform: uppercase;
    }

    ${media.lessThan("large")`
        width: 100%;    
    `}
`